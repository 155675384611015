document.addEventListener('DOMContentLoaded', () => {
  const banner = document.getElementById('banner');
  const text = document.querySelector('.ticker');

  const initializeBanner = () => {
    // Clear any previous content
    banner.innerHTML = '';

    // Add enough repeated items to fill the viewport, with a minimum of 10 items
    const bannerWidth = banner.offsetWidth;
    const textWidth = text.offsetWidth + 100; // Text width + padding
    const repeatCount = Math.max(13, Math.ceil(bannerWidth / textWidth) + 2);

    // Add repeated text
    let repeatedText = '';
    for (let i = 0; i < repeatCount; i++) {
      repeatedText += `<span class="ticker">${text.textContent}</span>`;
    }
    banner.innerHTML = repeatedText;
  };

  // Initialize the banner
  initializeBanner();

  // Reinitialize on resize
  window.addEventListener('resize', initializeBanner);
});
